import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"

import CardapioSandubasBrasilia from "../components/CardapioSandubasBrasilia"
import CardapioAperitivosBrasilia from "../components/CardapioAperitivosBrasilia"
import CardapioBaldeFrangoBrasilia from "../components/CardapioBaldeFrangoBrasilia"
import CardapioSaladasBrasilia from "../components/CardapioSaladasBrasilia"
import MenuBrasilia from "../components/MenuBrasilia"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <MenuBrasilia />
    <CardapioSandubasBrasilia />
    <CardapioAperitivosBrasilia />
    <CardapioBaldeFrangoBrasilia />
    <CardapioSaladasBrasilia />
  </Layout>
)

export default IndexPage
