import React from "react"
import Divider from "../Divider"

import bg from "../../images/go-burguer-bg-frango.jpg"
import * as S from "./styles"

const CardapioBaldeFrangoBrasilia = () => {
  return (
    <>
      <S.ComponentWrapper>
        <S.Container>
          <S.Title>Balde de Frango</S.Title>
          <S.SubTitle margin="10px 0 0">
            Acompanha molho de sua escolha
          </S.SubTitle>
          <S.SubTitle margin="10px 0 40px 0">R$19,00</S.SubTitle>
          <S.Col width="50%">
            <S.Text fontSize="16px" margin="5px 0 10px 0">
              Chicken strip
            </S.Text>
            <S.Text fontSize="14px">TIRAS DE FRANGO EMPANADO CROCANTE</S.Text>
            <S.Text
              fontWeight="normal"
              fontSize="14px"
              textTransform="initial"
              margin="0 0 25px 0"
            >
              (Acompanha molho de sua escolha)
              <br />
              Serve até 2 pessoas
            </S.Text>
            <S.Text fontSize="16px" margin="5px 0 10px 0">
              Buffalo Wings
            </S.Text>
            <S.Text fontSize="14px">
              COXINHAS DA ASA FRITAS, COBERTAS COM O MOLHO BUFFALO, LEVEMENTE
              PICANTE.
            </S.Text>
            <S.Text
              fontWeight="normal"
              fontSize="14px"
              textTransform="initial"
              margin="0 0 25px 0"
            >
              (Acompanha molho de sua escolha)
              <br />
              Serve até 2 pessoas
            </S.Text>
          </S.Col>
          <S.Col width="35%">
            <S.Text fontSize="17px" margin="0 0 10px 0">
              Molhos
            </S.Text>
            <S.TextStepHolder>
              <S.Text>Tradicional</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Maionese caseira
                <br />
                temperada com condimentos)
              </S.Text>
              <S.Text>Picante</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Molho picante a base
                <br />
                de condimentos e pimenta)
              </S.Text>
              <S.Text>Rosé</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
                margin="0 0 20px 0"
              >
                (Catchup, maionese
                <br />e condimentos)
              </S.Text>
              <S.Text>Barbecue</S.Text>
              <S.Text
                fontWeight="normal"
                fontSize="14px"
                textTransform="initial"
              >
                (Catchup e condimentos)
              </S.Text>
            </S.TextStepHolder>
          </S.Col>
        </S.Container>
      </S.ComponentWrapper>
      <Divider content={bg} />
    </>
  )
}

export default CardapioBaldeFrangoBrasilia
