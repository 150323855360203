export const adicionais = [
  { nome: "carne", valor: "R$10,00" },
  { nome: "queijo", valor: "R$2,00" },
  { nome: "bacon", valor: "R$2,50" },
  { nome: "tomate", valor: "R$1,50" },
  { nome: "alface", valor: "R$1,50" },
  { nome: "cebola", valor: "R$1,50" },
  { nome: "ovo", valor: "R$2,00" },
]

export const aperitivos = [
  {
    nome: "batata frita chips",
    descricao: "",
    conteudo: [{ porcao: "2 pessoas -", valor: "R$12,00" }],
  },
  {
    nome: "onion rings",
    descricao: "",
    conteudo: [{ porcao: "2 pessoas -", valor: "R$17,00" }],
  },
]

export const saladas = [
  {
    nome: "salada mix",
    valor: "R$20,00",
    descricao:
      "Alface, tomate cereja, muçarela em cubos, cenoura ralada, repolho roxo e pepino - Acompanha molho de sua escolha",
  },
]

export const adicionaisSalada = [
  { nome: "filé de frango", valor: "R$5,00" },
  { nome: "contra filé grelhado", valor: "R$9,00" },
  { nome: "filé mignon grelhado", valor: "R$12,00" },
  { nome: "lombo suíno", valor: "R$6,00" },
  { nome: "chicken strip", valor: "R$7,00" },
  { nome: "hambúrguer", valor: "R$7,00" },
]
