import styled from "styled-components"

import bg from "../../images/go-burguer-pattern.png"

export const ComponentWrapper = styled.div`
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    url(${bg});
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  margin: 60px 0;
  width: 90%;
  max-width: 1080px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Text = styled.p`
  font-family: Arvo;
  text-transform: ${({ textTransform }) => textTransform || `uppercase`};
  font-weight: ${({ fontWeight }) => fontWeight || `bold`};
  font-size: ${({ fontSize }) => fontSize || `initial`};
  text-align: ${({ textAlign }) => textAlign || `left`};
  margin: ${({ margin }) => margin || `initial`};
  line-height: 1.2;
`

export const Title = styled.h2`
  font-family: Arvo;
  text-align: center;
  width: 100%;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 30px;
`
export const Col = styled.div`
  width: ${({ width }) => width || `auto`};

  @media (max-width: 768px) {
    width: 100%;
  }
`
