import React from "react"
import Divider from "../Divider"

import { aperitivos } from "../../assets/cardapio-brasilia"
import bg from "../../images/go-burguer-bg-batata.jpg"
import * as S from "./styles"

const CardapioAperitivosBrasilia = () => (
  <>
    <S.ComponentWrapper>
      <S.Container>
        <S.Title>Aperitivos</S.Title>
        {aperitivos.map((aperitivo, index) => (
          <S.Col width="100%">
            <S.Text fontSize="16px" margin="20px 0 5px 0" textAlign="center">
              {aperitivo.nome}
            </S.Text>
            <S.Text
              fontWeight="normal"
              fontSize="15px"
              textTransform="initial"
              textAlign="center"
            >
              {aperitivo.conteudo[0].porcao} {aperitivo.conteudo[0].valor}
            </S.Text>
          </S.Col>
        ))}
      </S.Container>
    </S.ComponentWrapper>
    <Divider content={bg} />
  </>
)

export default CardapioAperitivosBrasilia
